import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword, sendEmailVerification  } from "firebase/auth";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import "../../libs/toastr/build/toastr.min.css"
import toastr from "../../libs/toastr/build/toastr.min.js"
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAfMiJV0WoI8E4es8qmANqw3qSWApsdJyI",
  authDomain: "ai-proj-c54e6.firebaseapp.com",
  databaseURL: "https://ai-proj-c54e6-default-rtdb.firebaseio.com",
  projectId: "ai-proj-c54e6",
  storageBucket: "ai-proj-c54e6.appspot.com",
  messagingSenderId: "525275819582",
  appId: "1:525275819582:web:69c8bc6199304a438eeb6f",
  measurementId: "G-2NPJ48VPDP"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider("6LfPLBEqAAAAAHjEYsdiiyIdKN0Yknmrba6Ffqgj"),
  isTokenAutoRefreshEnabled: true 
})
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
toastr.options = {
    'closeButton':true,
    'debug': false,
    'newestOnTop': true,
    'progressBar': true,
    'positionClass': 'toast-top-right',
    'preventDuplicates': false,
    'showDuration': '1000',
    'hideDuration': '1000',
    'timeOut': '5000',
    'extendedTimeOut': '1000',
    'showEasing': 'swing',
    'hideEasing': 'linear',
    'showMethod': 'fadeIn',
    'hideMethod': 'fadeOut',
  }
  
document.getElementById("password-login").addEventListener("click", function(){
  
  var email = document.getElementById("useremail").value
  var password = document.getElementById("userpassword").value

    if((email == "" || email == null) && (password !== "" || password !== null)){
      toastr.error("Please enter Email", "Email Required!")
      return;
    }else if((email !== "" || email !== null) && (password == "" || password == null)){
      toastr.error("Please enter Password", "Password Required!")
      return;
    }
    else if((email == "" || email == null) && (password == "" || password == null)){
      toastr.success("Please enter Email and Password", "Email & Password Required!")
      return;
    }
    localStorage.removeItem("isReturnlog")
    document.getElementById("password-login").setAttribute("hidden","hidden")
    document.getElementById("password-layout").setAttribute("hidden","hidden")
    document.getElementById("or").setAttribute("hidden","hidden")
    document.getElementById("google").setAttribute("hidden","hidden")
    document.getElementById("loading-btn").removeAttribute("hidden")
    document.getElementById("loading-layout").removeAttribute("hidden")
    
    

    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      if(!userCredential.user.emailVerified){
        sendEmailVerification(auth.currentUser)
        window.location.href = "/auth-email-verification"
        return;
      }
        document.getElementById("loading-btn").setAttribute("hidden","hidden")
        document.getElementById("loading-layout").setAttribute("hidden","hidden")
        document.getElementById("or").removeAttribute("hidden")
        document.getElementById("google").removeAttribute("hidden")
        document.getElementById("password-login").removeAttribute("hidden")
        document.getElementById("password-layout").removeAttribute("hidden")
        window.location.href = "/dashboard"
      
    })
    .catch((error) => {
        document.getElementById("loading-btn").setAttribute("hidden","hidden")
        document.getElementById("loading-layout").setAttribute("hidden","hidden")
        document.getElementById("or").removeAttribute("hidden")
        document.getElementById("google").removeAttribute("hidden")
        document.getElementById("password-login").removeAttribute("hidden")
        document.getElementById("password-layout").removeAttribute("hidden")
        const errorCode = error.code;
        const errorMessage = error.message;
        if(errorCode == "auth/invalid-email"){
          toastr.error("Invalid Email. Please try again","Failed")
        }else if(errorCode == "auth/weak-password"){
          toastr.error("Password must be at least 6 characters","Failed")
        }else if(errorCode == "auth/email-already-in-use") {
          toastr.error("Email already exists.","Failed")
        }else if(errorCode == "auth/invalid-credential") {
          toastr.error("Invalid Credentials. Please try again!","Failed")
        }else {
          toastr.error("Please try again.","Failed")
        }
    });
    
})

document.getElementById("google").addEventListener("click", async function(){
  document.getElementById("spinner-google-login").removeAttribute("hidden")
  localStorage.setItem('isReturnlog', "yes");
  var isthere = localStorage.getItem('isReturnlog');
  if(isthere !== null && isthere == "yes"){
    document.getElementById("form-ee").setAttribute("hidden","hidden")
    document.getElementById("the-divv").style["display"] = "flex"
    document.getElementById("the-divv").style["flex-direction"] = "row"
    document.getElementById("the-divv").style["align-items"] = "center"
    document.getElementById("the-divv").style["justify-content"] = "center"
    document.getElementById("spinner-google-bigg").removeAttribute("hidden")

    await signInWithPopup(auth, provider)
    .then((result) => {
      if(result.providerId == "google.com"){
        localStorage.removeItem("isReturnlog")
        window.location.href = "/dashboard"
      }else{
        localStorage.removeItem("isReturnlog")
        document.getElementById("form-ee").removeAttribute("hidden")
        document.getElementById("the-divv").style["display"] = null
        document.getElementById("the-divv").style["flex-direction"] = null
        document.getElementById("the-divv").style["align-items"] = null
        document.getElementById("the-divv").style["justify-content"] = null
        document.getElementById("spinner-google-bigg").setAttribute("hidden","hidden")
        document.getElementById("spinner-google-login").setAttribute("hidden","hidden")
        toastr.error("Something happened. Please try again!","Error!")
      }
    }).catch((error) => {
        localStorage.removeItem("isReturnlog")
        document.getElementById("form-ee").removeAttribute("hidden")
        document.getElementById("the-divv").style["display"] = null
        document.getElementById("the-divv").style["flex-direction"] = null
        document.getElementById("the-divv").style["align-items"] = null
        document.getElementById("the-divv").style["justify-content"] = null
        document.getElementById("spinner-google-bigg").setAttribute("hidden","hidden")
        document.getElementById("spinner-google-login").setAttribute("hidden","hidden")
        const errorCode = error.code;
        const errorMessage = error.message;
        if(errorCode == "auth/invalid-email"){
          toastr.error("Invalid Email. Please try again","Failed")
        }else if(errorCode == "auth/invalid-password"){
          toastr.error("Password must be at least 6 characters","Failed")
        }else if(errorCode == "auth/user-not-found") {
          toastr.error("User does not exists. Sign up now","Failed")
        }
    });
  }
})

/*var isthere = localStorage.getItem('isReturnlog');
if(isthere !== null && isthere == "yes"){

    document.getElementById("form-ee").setAttribute("hidden","hidden")
    document.getElementById("the-divv").style["display"] = "flex"
    document.getElementById("the-divv").style["flex-direction"] = "row"
    document.getElementById("the-divv").style["align-items"] = "center"
    document.getElementById("the-divv").style["justify-content"] = "center"
    document.getElementById("spinner-google-bigg").removeAttribute("hidden")

    getRedirectResult(auth).then((result) => {
      if(result.providerId == "google.com"){
        localStorage.removeItem("isReturnlog")
        window.location.href = "/dashboard"
      }else{
        localStorage.removeItem("isReturnlog")
        document.getElementById("form-ee").removeAttribute("hidden")
        document.getElementById("the-divv").style["display"] = null
        document.getElementById("the-divv").style["flex-direction"] = null
        document.getElementById("the-divv").style["align-items"] = null
        document.getElementById("the-divv").style["justify-content"] = null
        document.getElementById("spinner-google-bigg").setAttribute("hidden","hidden")
        toastr.error("Something happened. Please try again!","Error!")
      }
      
    }).catch((error) => {
      localStorage.removeItem("isReturnlog")
      document.getElementById("form-ee").removeAttribute("hidden")
      document.getElementById("the-divv").style["display"] = null
      document.getElementById("the-divv").style["flex-direction"] = null
      document.getElementById("the-divv").style["align-items"] = null
      document.getElementById("the-divv").style["justify-content"] = null
      document.getElementById("spinner-google-bigg").setAttribute("hidden","hidden")
      const errorCode = error.code;
      const errorMessage = error.message;
      if(errorCode == "auth/invalid-email"){
        toastr.error("Invalid Email. Please try again","Failed")
      }else if(errorCode == "auth/invalid-password"){
        toastr.error("Password must be at least 6 characters","Failed")
      }else if(errorCode == "auth/user-not-found") {
        toastr.error("User does not exists. Sign up now","Failed")
      }
    });
}*/
